<template>
  <div class="capacityRemain" v-loading="loading">
    <div id="capacityRemaining" class="capacityRemainingCanvas"></div>
    <div class="capacityRight">
      <div class="conetnt">
        <div class="title">
          {{ $t('dashboardCapacityMonit.Occupied') }}
        </div>
        <div class="value">
          {{ formatCapacity(remain && remain.storageCapacity * 1024) || '0KB' }}
        </div>
      </div>
      <div class="conetnt">
        <div class="title">
          {{ $t('dashboardCapacityMonit.Total Space') }}
        </div>
        <div class="value">
          {{ formatCapacity(remain && remain.totalCapacity * 1024) || '0KB' }}
        </div>
      </div>
    </div>
    <div class="lineCenter"></div>
    <div class="companyNumber">
      <div class="content">
        <div class="title">{{ $t('dashboardCapacityMonit.Partner Account') }}</div>
        <div class="value">{{ (topData && topData.customer) || 0 }}</div>
      </div>
      <div class="content">
        <div class="title">{{ $t('dashboardCapacityMonit.Employee Account') }}</div>
        <div class="value">{{ (topData && topData.member) || 0 }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import { getCompanyBasicStatistics, basicStatistics } from '@/api/chart';
import { formatCapacity } from '@/utils/utils';

export default {
  name: 'capacityRemaining',
  emits: ['updateRemain'],
  data() {
    return {
      loading: true,
      remain: null,
      myChart: null,
      topData: null,
      formatCapacity: formatCapacity
    }
  },
  mounted() {
    this.getCapacityRemaining();
  },
  activated() {
    this.getCapacityRemaining();
  },
  watch: {
    'window.vm._i18n.locale'() {
      this.remaining(this.remain);
    }
  },
  methods: {
    getCapacityRemaining() {
      try {
        this.loading = true;
        getCompanyBasicStatistics().then(data => {
          this.remain = data;
          this.remaining(data);
        })
        basicStatistics({
          startTime: dayjs().startOf('year').format('YYYY-MM-DD HH:mm:ss'),
          endTime: dayjs().format('YYYY-MM-DD HH:mm:ss')
        }).then(data => {
          this.topData = data;
        })
      } catch (error) {}
    },
    remaining(item) {
      this.myChart = this.$echartsInit(
        document.getElementById('capacityRemaining')
      );
      const storage = formatCapacity(
        (Number(item?.totalCapacity) - Number(item?.storageCapacity)) * 1024
      );
      this.myChart.setOption({
        series: [
          {
            tooltip: {
              trigger: 'item',
              formatter(params) {
                return `<div>
                  ${params.marker}${(params.data * 100).toFixed(2)}%
                </div>`
              }
            },
            type: 'liquidFill',
            data: [1 - (Number(item?.storageCapacity) / Number(item.totalCapacity))],
            color: ['#1890FF'],
            radius: '82%',
            label: {
              normal: {
                formatter: () => {
                  return `${window.vm._i18n.locale === 'zh' ? '剩余' : 'Free'}\n\n{a|${storage}}`;
                },
                textStyle: {
                  fontSize: 16,
                  color: '#637381',
                  rich: {
                    a: {
                      fontSize: 20,
                    },
                  },
                },
                position: ['50%', '50%'],
                align: 'center',
                verticalAlign: 'middle',
              },
            },
            outline: {
              borderDistance: 2,
              itemStyle: {
                borderWidth: 2,
                borderColor: 'rgba(24, 144, 255, 1)',
                shadowBlur: 0,
                shadowColor: 'white',
              },
            },
            backgroundStyle: {
              color: '#ffffff'
            },
            itemStyle: {
              shadowBlur: 0,
              shadowColor: 'white',
            },
          },
        ],
        tooltip: {
          show: true,
          confine: true,
        },
      });
      this.loading = false;
      window.addEventListener('resize', () => this.myChart.resize());
    }
  }
};
</script>
<style lang="less" scoped>
.capacityRemain {
  display: flex;
  align-items: center;
  height: calc(100% - 30px);
  .capacityRemainingCanvas {
    flex: 1;
    height: 100%;
  }
  .capacityRight {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 30px;
    .conetnt {
      .title {
        font-size: 12px !important;
        line-height: 17px !important;
        color: #637381 !important;
        font-weight: 400 !important;
      }
      .value {
        font-size: 24px;
        line-height: 28px;
        font-weight: 600;
        color: #122545;
      }
    }
  }
  .lineCenter {
    width: 1px;
    height: 150px;
    background: #E0E4EA;
  }
  .companyNumber {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-left: 30px;
    .content {
      .title {
        font-size: 12px !important;
        line-height: 28px !important;
        font-weight: 400 !important;
        color: #637381 !important;
      }
      .value {
        font-size: 20px;
        line-height: 23px;
        color: #122545;
        font-weight: 600;
      }
    }
  }
}
</style>
