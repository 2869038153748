<template>
  <div class="capacityWrapper">
    <div class="capacityTopBox">
      <el-card>
        <div class="title">{{ $t('dashboardCapacityMonit.Storage Usage') }}</div>
        <capacityRemaining @updateRemain="remain = $event"></capacityRemaining>
      </el-card>
      <el-card>
        <div class="title">
          {{ $t('dashboardCapacityMonit.File occupancy distribution') }}
          <div class="translateButton">
            <el-tabs class="special_tabs" v-model="type">
              <el-tab-pane :label="$t(`dashboardCapacityMonit.File`)" name="1" />
              <el-tab-pane :label="$t(`dashboardCapacityMonit.Account`)" name="2" />
            </el-tabs>
          </div>
        </div>
        <capacityOccupancyDistribution
          :item="remain"
          :type="type"
        ></capacityOccupancyDistribution>
      </el-card>
    </div>
    <el-card class="capacityBottomBox">
      <div class="title">
        {{ $t('dashboardCapacityMonit.Employee Name') }}
        <el-input
          :placeholder="$t('dashboardCapacityMonit.Employee Name')"
          style="width: 200px;"
          class="filter-item"
          auto-complete="off"
          v-model="name"
          size="small"
          clearable
          @keyup.enter.native="getTableData({ 
            username: name 
          })"
        >
          <i
            style="padding-top: 1px"
            class="el-icon-search el-input__icon"
            slot="prefix"
          >
          </i>
        </el-input>
      </div>
      <div class="capacityTable" v-loading="loading">
        <el-table
          :data="tableData"
          height="100%"
          size="small"
        >
          <el-table-column type="index" sortable label="No." width="80" />
          <el-table-column
            prop="username"
            :label="$t('dashboardCapacityMonit.Employee Name')"
          ></el-table-column>
          <el-table-column
            prop="fileNumber"
            :label="$t('dashboardCapacityMonit.File Quantity')"
            sortable
          >
          </el-table-column>
          <el-table-column
            prop="fileSize"
            :label="$t('dashboardCapacityMonit.Storage')"
            sortable
          >
            <template slot-scope="scope">
              <div>{{ (scope.row.fileSize / 1024).toFixed(2) }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="orderNumber"
            :label="$t('dashboardCapacityMonit.Involved Order')"
            sortable
          >
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        @size-change="changePageSizeHandler"
        @current-change="changePageHandler"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40, 50]"
        :total="totalElement"
        background
        layout="prev, pager, next, sizes, total, jumper"
      />
    </el-card>
  </div>
</template>
<script>
import { onActivated, onMounted, reactive, toRefs } from '@vue/composition-api';
import capacityRemaining from './components/capacityRemaining.vue';
import capacityOccupancyDistribution from './components/capacityOccupancyDistribution.vue';
import { getCompanyMemberStatistics } from '@/api/chart';
export default {
  name: 'Capacity',
  components: {
    capacityRemaining,
    capacityOccupancyDistribution,
  },
  setup() {
    const state = reactive({
      loading: true,
      name: '',
      remain: null,
      type: '1',
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      totalElement: 1,
    });

    function changePageHandler(page) {
      state.currentPage = page;
      getTableData();
    }

    function changePageSizeHandler(pageSize) {
      state.pageSize = pageSize;
      getTableData();
    }

    function getTableData(body) {
      if (state.tableData && state.tableData.length === 0) {
        state.loading = true;
      }
      getCompanyMemberStatistics({
        currentPage: state.currentPage,
        pageSize: state.pageSize,
        ...body,
      }).then(data => {
        state.tableData = data.list;
        state.totalElement = data.total;
      }).finally(() => {
        state.loading = false;
      });
    }

    onMounted(() => {
      getTableData();
    });
    onActivated(() => {
      getTableData();
    });

    return {
      ...toRefs(state),
      changePageHandler,
      changePageSizeHandler,
      getTableData,
    };
  },
};
</script>
<style lang="less" scoped>
@import "./style/capacityMonit.less";
</style>
