<template>
  <div class="capacity-occupancy-distribution" v-loading="loading">
    <div
      id="capacity-occupancy-distribution"
      class="capacity-occupancy-distribution-canvas"
    ></div>
    <div class="table">
      <el-table
        :data="remain"
        :show-header="false"
        style="width: 100%"
        height="240px"
        @cell-mouse-enter="cellMouseEnter"
        @cell-mouse-leave="cellMouseLeave"
        :row-class-name="tableRowClassName">
        <el-table-column
          prop="name"
          min-width="130">
          <template slot-scope="scope">
            <div class="flex-table">
              <span class="color_dian" :style="`background: ${myChart._chartsViews[0]._data._itemVisuals[scope.$index].style.fill}`"></span>
              {{ scope.row.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="storageSize">
        </el-table-column>
        <el-table-column
          prop="proportion">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { getDict } from "@/api/common";
import { getFileSpacePie } from "@/api/chart";

export default {
  name: "capacityOccupancyDistribution",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: "1",
    },
  },
  data() {
    return {
      loading: true,
      remain: null,
      myChart: null
    }
  },
  computed: {
    ...mapGetters(['fileType'])
  },
  mounted() {
    this.getOccupancyDistribution();
  },
  activated() {
    this.getOccupancyDistribution();
  },
  methods: {
    occupancyDistribution(arr, enumArr) {
      this.myChart = this.$echartsInit(
        document.getElementById("capacity-occupancy-distribution")
      );
      this.myChart.setOption({
        tooltip: {
          trigger: "item",
          confine: true,
          formatter: "{b}: {c}MB ({d}%)",
        },
        legend: {
          orient: "vertical",
          top: "middle",
          right: 0,
          type: "scroll",
          textStyle: {
            fontSize: 16
          },
          show: false,
        },
        series: [
          {
            type: "pie",
            center: ["50%", "50%"],
            avoidLabelOverlap: true,
            minAngle: 5,
            label: {
              show: false,
            },
            data: arr?.map((item, index) => {
              const msg = enumArr?.find(
                (vo) => vo.code === item.fileType?.code
              );
              return {
                name:
                  this.type === "5"
                    ? msg?.message ?? item.fileType?.message
                    : item.name,
                value: (item.fileSize / 1024).toFixed(2),
              };
            })
          },
        ],
      });
      window.addEventListener("resize", () => this.myChart.resize());
      this.myChart.on("mouseover", (params) => {
        this.myChart.dispatchAction({
          type: "downplay",
        });
        this.myChart.dispatchAction({
          type: "highlight",
          dataIndex: params.dataIndex,
        });
      });
      this.myChart.on("mouseout", (params) => {
        this.myChart.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: params.dataIndex,
        });
      });
      this.loading = false;
    },
    async getOccupancyDistribution() {
      try {
        this.loading = true;
        getFileSpacePie({
          type: this.type,
        }).then((data) => {
          var total = 0;
          for (var i = 0, l = data.length; i < l; i++) {
            total += data[i].fileSize;
          }
          data.forEach(item => {
            item.proportion = (((item.fileSize / total) || 0) * 100).toFixed(2)+'%'
            item.storageSize = (item.fileSize / 1024).toFixed(2) + 'MB'
            item.name = this.$isFindData(this.fileType, item.fileType, 'configCode', 'configName') || item.name
          })
          this.remain = data;
          this.occupancyDistribution(data);
        }).finally(() => this.loading = false);
      } catch (error) {}
    },
    dispatchEchartsHighlightAction(index) {
      this.myChart.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: index,
      });
    },
    dispatchEchartsDownplayAction(index) {
      this.myChart.dispatchAction({
        type: "downplay",
        seriesIndex: 0,
        dataIndex: index,
      });
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    cellMouseEnter(row) {
      this.dispatchEchartsHighlightAction(row.index)
    },
    cellMouseLeave(row) {
      this.dispatchEchartsDownplayAction(row.index)
    }
  },
  watch: {
    type() {
      this.getOccupancyDistribution();
    },
    'window.vm._i18n.locale'() {
      getDict("FILE_TYPE").then((res) => {
        if (res?.data?.data.length > 0) {
          this.occupancyDistribution(this.remain, res?.data?.data);
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.capacity-occupancy-distribution {
  width: 100%;
  height: calc(100% - 30px);
  display: flex;
  justify-content: space-between;
  .capacity-occupancy-distribution-canvas {
    width: 50%;
    height: 100%;
  }
  .table {
    width: 50%;
    .color_dian {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 5px;
      flex-shrink: 0;
    }
    /deep/ .el-table {
      &::before {
        display: none;
      }
      td {
        border-bottom: none;
      }
    }
  }
}
</style>
